import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { AppThunk } from "../../store";
import { ISliceStatus } from "../../interfaces";
import snack from "../../utils/snack";

export type ILabelAction = "good" | "bad" | "trash" | "re";
export interface ILabel {
  table_id?: string;
  created_by?: {
    email: string;
    name: string;
  };
  website: string;
  theme?: string;
  action: ILabelAction;
  comment?: string;
}

interface ISet {
  [key: string]: true;
}

interface IState {
  goodCompanies: ISet;
  badCompanies: ISet;
  trash: ISet;
  status: ISliceStatus;
  theme?: string;
  themes: string[];
}

const initialState: IState = {
  goodCompanies: {},
  badCompanies: {},
  trash: {},
  status: "idle",
  themes: [],
};

export const labelsSlice = createSlice({
  name: "labels",
  initialState,
  reducers: {
    statusChanged: (state, action: PayloadAction<ISliceStatus>) => {
      state.status = action.payload;
    },
    fetched: (
      state,
      action: PayloadAction<{
        items: ILabel[];
        theme: string;
      }>
    ) => {
      const goodCompanies: ISet = {};
      const badCompanies: ISet = {};
      const trash: ISet = {};
      action.payload.items.forEach((item) => {
        if (item.action === "good") {
          goodCompanies[item.website] = true;
        } else if (item.action === "bad") {
          badCompanies[item.website] = true;
        } else if (item.action === "trash") {
          trash[item.website] = true;
        }
      });
      state.goodCompanies = goodCompanies;
      state.badCompanies = badCompanies;
      state.trash = trash;
      state.theme = action.payload.theme;
      state.status = "ready";
    },
    labelAdded: (state, action: PayloadAction<IPostLabelBody>) => {
      for (const website of action.payload.websites) {
        if (action.payload.action === "good") {
          state.goodCompanies[website] = true;
          delete state.badCompanies[website];
          delete state.trash[website];
        } else if (action.payload.action === "bad") {
          state.badCompanies[website] = true;
          delete state.goodCompanies[website];
          delete state.trash[website];
        } else if (action.payload.action === "trash") {
          state.trash[website] = true;
          delete state.goodCompanies[website];
          delete state.badCompanies[website];
        }
      }
    },
    labelDeleted: (state, action: PayloadAction<IDeleteLabelBody>) => {
      for (const website of action.payload.websites) {
        delete state.goodCompanies[website];
        delete state.badCompanies[website];
        delete state.trash[website];
      }
    },
    themesFetched: (state, action: PayloadAction<string[]>) => {
      state.themes = action.payload;
    },
  },
  extraReducers: {
    "session/logOut": () => initialState,
  },
});

export const {
  statusChanged,
  fetched,
  labelAdded,
  labelDeleted,
  themesFetched,
} = labelsSlice.actions;

export const getLabels =
  (query: { theme: string }): AppThunk =>
  async (dispatch) => {
    dispatch(statusChanged("loading"));

    const res = await api.fetch({
      path: "/labels",
      method: "GET",
      query,
    });
    if (res.ok) {
      dispatch(fetched({ items: res.payload, theme: query.theme }));
    }
  };

interface IDeleteLabelBody {
  websites: string[];
  theme: string;
}
interface IPostLabelBody {
  websites: string[];
  table_id?: string;
  action: ILabelAction;
  theme?: string;
}

export const postLabels =
  (body: IPostLabelBody, quite?: boolean): AppThunk =>
  async (dispatch) => {
    if (!quite) {
      dispatch(labelAdded(body));
      if (body.action !== "re") {
        snack.success(
          `${
            body.websites.length === 1
              ? body.websites[0]
              : `${body.websites.length} labels`
          }: ${body.action}`
        );
      }
    }
    await api.fetch({
      path: "/label",
      method: "POST",
      body,
    });
  };

export const deleteLabels =
  (body: IDeleteLabelBody): AppThunk =>
  async (dispatch) => {
    await api.fetch({
      path: "/label",
      method: "DELETE",
      body,
    });
    dispatch(labelDeleted(body));
  };

interface IToSalesforceBody {
  website: string;
  theme: string;
  table_id: string;
}

export const toSalesforce =
  (body: IToSalesforceBody): AppThunk =>
  async () => {
    snack.info(`Sending ${body.website} to Salesforce...`);
    const res = await api.fetch({
      path: "/salesforce",
      method: "POST",
      body,
    });
    if (res.ok) {
      snack.success(`🚀 Sent ${body.website} to Salesforce`);
    }
  };

export const getThemes = (): AppThunk => async (dispatch) => {
  const res = await api.fetch({
    path: "/themes",
    method: "GET",
  });
  if (res.ok) {
    dispatch(themesFetched(res.payload));
  }
};

export default labelsSlice.reducer;
