import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import SpacingBox from "../SpacingBox";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Tooltip from "@mui/material/Tooltip";

interface IProps {
  onClick: (key: string) => void;
  website: string;
}
export default function OtherActions(props: IProps) {
  const { onClick, website } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { trash } = useSelector((state: RootState) => state.labels);
  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => onClick("ArrowLeft")}>
          ⬅️ - Previous company
        </MenuItem>
        <MenuItem onClick={() => onClick("ArrowRight")}>
          ➡️ - Next company
        </MenuItem>
        <MenuItem onClick={() => onClick("T")}>
          <Tooltip title="Mark this website as spam/vice/junk">
            <span>T - Trash</span>
          </Tooltip>
        </MenuItem>
        <MenuItem sx={{ width: 200 }} onClick={() => onClick("r")}>
          R - Rescrape
        </MenuItem>
        <MenuItem onClick={() => onClick("d")}>
          <Tooltip title="Unlabel this website">
            <span>D - Delete label</span>
          </Tooltip>
        </MenuItem>
        <MenuItem onClick={() => onClick("s")}>
          <Tooltip title="Send this company to salesforce">
            <span>S - Send to Salesforce</span>
          </Tooltip>
        </MenuItem>
      </Menu>
    </>
  );
}
