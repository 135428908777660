import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import useQuery from "../../hooks/useQuery";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { saveTable } from "../tableSelect/tableSelectSlice";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Typography from "@mui/material/Typography";
import SpacingBox from "../SpacingBox";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { SingleSelect } from "../select/Select";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";

interface IProps {
  tableId?: string;
  disableTooltip?: boolean;
}

export default function SaveTablePopper(props: IProps) {
  const tableId = props.tableId;
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state: RootState) => state.tableSelect.status === "loading"
  );
  const selectedTable = useSelector((state: RootState) =>
    tableId ? state.tableSelect.tablesById[tableId] : undefined
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopperClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const { register, errors, handleSubmit, setValue } = useForm<{
    tableName: string;
    number: number;
    unit: moment.unitOfTime.DurationConstructor;
  }>({
    defaultValues: {
      tableName: selectedTable?.table.table_id || "",
      number: 1,
      unit: "months",
    },
  });

  register("unit");

  const [isExpiring, setIsExpiring] = useState(true);

  return (
    <Box>
      <Tooltip
        disableHoverListener={props.disableTooltip}
        title="Save as a new table"
      >
        <span>
          <LoadingButton
            disabled={isLoading}
            onClick={handlePopperOpen}
            loading={isLoading}
          >
            <SaveAsIcon />
          </LoadingButton>
        </span>
      </Tooltip>
      <Popper sx={{ zIndex: 3, width: 350 }} open={open} anchorEl={anchorEl}>
        <Paper elevation={8}>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Typography variant="h5">Save as a new table</Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              p: 2,
            }}
          >
            <TextField
              label="Table name"
              autoFocus
              name="tableName"
              size="small"
              defaultValue={selectedTable?.table.table_id}
              fullWidth
              inputRef={register({
                required: true,
                pattern: /^[a-z0-9_]+$/i,
              })}
              error={!!errors.tableName}
              helperText={
                errors.tableName
                  ? "Allowed characters: a-z, 0-9, _. No spaces."
                  : null
              }
            />
            <SpacingBox />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Checkbox
                checked={isExpiring}
                onChange={() => {
                  setIsExpiring(!isExpiring);
                }}
              />
              <SpacingBox />
              <TextField
                disabled={!isExpiring}
                // number of units
                fullWidth
                label="Expires in"
                size="small"
                name="number"
                type="number"
                defaultValue={12}
                inputRef={register({
                  pattern: selectedTable?.table.table_id.startsWith("ent_")
                    ? /^ent_[0-9]+$/i
                    : /^[0-9]+$/i,
                  min: 0,
                  max: 1000000,
                })}
                error={!!errors.number}
                helperText={errors.number?.message}
              />
              <SpacingBox />
              <SingleSelect
                disabled={!isExpiring}
                noDefault
                fullWidth
                defaultValue="months"
                options={["days", "hours", "minutes", "months"]}
                label="Days/hours/minutes"
                onSelect={(val) => {
                  setValue("unit", val);
                }}
              />
            </Box>
          </Box>

          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              p: 1,
              justifyContent: "space-between",
            }}
          >
            <Button onClick={handlePopperClose}>Cancel</Button>
            <Button
              disabled={!tableId}
              onClick={handleSubmit((data) => {
                dispatch(
                  saveTable({
                    fromTableId: tableId!,
                    toTableName: data.tableName,
                    ...(isExpiring && {
                      expirationTime: moment()
                        .add(data.number, data.unit)
                        .valueOf(),
                    }),
                  })
                );
                handlePopperClose();
              })}
            >
              Save
            </Button>
          </Box>
        </Paper>
      </Popper>
    </Box>
  );
}
