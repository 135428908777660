import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import useQuery from "../../hooks/useQuery";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import snack from "../../utils/snack";
import { useEffect } from "react";
import { getLabels, getThemes } from "../companySwiper/labelsSlice";
import useIsTabActive from "../../hooks/useIsTabActive";

interface IOption {
  id: string;
  inputValue?: string;
}

const filter = createFilterOptions<IOption>();

export default function ThemeSelect() {
  const { query, setQuery } = useQuery<{ theme?: string }>();
  const { theme } = query;
  const sliceTheme = useSelector((state: RootState) => state.labels.theme);
  const options: IOption[] = useSelector((state: RootState) =>
    state.labels.themes.map((t) => ({
      id: t,
    }))
  );
  const value: IOption = {
    id: theme || "",
  };

  const dispatch = useDispatch();
  const isTabActive = useIsTabActive();
  useEffect(() => {
    if (theme && theme !== sliceTheme && isTabActive) {
      dispatch(getLabels({ theme }));
    }
  }, [theme, sliceTheme, dispatch, isTabActive]);
  useEffect(() => {
    if (options.length === 0) {
      dispatch(getThemes());
    }
  }, [dispatch]);

  return (
    <Autocomplete
      value={value}
      onChange={(_, newValue) => {
        let val: string;
        if (typeof newValue === "string") {
          val = newValue;
        } else if (newValue?.inputValue) {
          val = newValue.inputValue;
        } else {
          val = newValue?.id!;
        }
        const re =
          /^[a-z][a-z_]{5,200}_(size|review|review_2|review_3|universe|ent)$/;
        // @ts-ignore
        if (!re.test(val) && !newValue?.id) {
          snack.error(
            "A theme must be a string of 5-200 lower-case characters and underscores ending with _size, _review, _review_2, _review_3, _ent or _universe"
          );
          return;
        }
        setQuery({ theme: val });
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.id);
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            id: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      size="small"
      clearOnBlur
      handleHomeEndKeys
      options={options}
      renderOption={(props: any, option) => (
        <OptionItem key={option} option={option} passThroughProps={props} />
      )}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.id;
      }}
      sx={{ width: 300, minWidth: 300 }}
      freeSolo
      renderInput={(params) => <TextField {...params} label="Theme" />}
    />
  );
}

function OptionItem(props: any) {
  const { option, passThroughProps } = props;
  return (
    <ListItemButton {...passThroughProps}>
      {/* <UserAvatar name={option} /> */}
      <ListItemText
        primary={`${option.id}`}
        // secondary={
        //   <>
        //     {option.expiresIn && <span>Expires {option.expiresIn}; </span>}
        //     {option.date && `Created at ${option.date}`}
        //     <br />
        //     {option.parent && (
        //       <>
        //         <span>{option.parent}</span>
        //         <br />
        //       </>
        //     )}
        //   </>
        // }
      />
    </ListItemButton>
  );
}
