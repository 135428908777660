import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Paper from "@mui/material/Paper";
import SourceTableSelect from "../tableSelect/SourceTableSelect";
import {
  ICompanySwiperQuery,
  companySwiperQuerySchema,
} from "./CompanySwiperQuery";
import useQuery from "../../hooks/useQuery";
import { RootState } from "../../store";
import { getTablePreview } from "../table/tableSlice";
import { GridColumnVisibilityModel } from "@mui/x-data-grid-premium";
import useLocalStorage from "../../hooks/useLocalStorage";
import ThemeSelect from "../theme/ThemeSelect";
import SpacingBox from "../SpacingBox";
import { deleteLabels, postLabels, toSalesforce } from "./labelsSlice";
import snack from "../../utils/snack";
import GoodBadButton from "./GoodBadButton";
import DebouncedTextField from "../debouncedTextField/DebouncedTextField";
import OtherActions from "./CompanySwiperOtherActions";

export const pageSize = 100;

export default function CompanySwiperControls() {
  const { query, setQuery } = useQuery<ICompanySwiperQuery>(
    companySwiperQuerySchema
  );

  const rowIndex = Number(query.rowIndex || 0);

  const pageIndex = Math.floor(rowIndex / pageSize);

  const website =
    (useSelector(
      (state: RootState) => state.table.rows[rowIndex % pageSize]?.website
    ) as string) || "";

  const [columnVisibilityModel] = useLocalStorage<GridColumnVisibilityModel>(
    "columnsVisibilityModel",
    {} as GridColumnVisibilityModel
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (!query.tableId) {
      return;
    }
    dispatch(
      getTablePreview(query.tableId, {
        offset: pageIndex * pageSize,
        limit: pageSize,
        excludedColumns: Object.keys(columnVisibilityModel).filter(
          (key) => !columnVisibilityModel[key]
        ),
      })
    );
  }, [query.tableId, pageIndex]);

  useEffect(() => {
    // listen to keydown event
    const listener = (e: KeyboardEvent) => {
      // if ctrl, alt, shift, meta, ignore
      if (e.ctrlKey || e.altKey || e.shiftKey || e.metaKey) {
        return;
      }
      if (["INPUT", "TEXTAREA"].includes((e.target as any).tagName)) {
        return;
      }
      e.preventDefault();
      if (!query.theme) {
        snack.warning("Please select a theme first");
        return;
      }

      switch (e.key) {
        case "ArrowLeft":
          if (rowIndex === 0) {
            return;
          }
          setQuery({ rowIndex: String(rowIndex - 1) });
          break;
        case "ArrowRight":
          setQuery({ rowIndex: String(rowIndex + 1) });
          break;
        case "ArrowUp":
          dispatch(
            postLabels({
              websites: [website],
              action: "good",
              theme: query.theme!,
              table_id: query.tableId!,
            })
          );
          setQuery({ rowIndex: String(rowIndex + 1) });
          break;
        case "ArrowDown":
          dispatch(
            postLabels({
              websites: [website],
              action: "bad",
              theme: query.theme!,
              table_id: query.tableId!,
            })
          );
          setQuery({ rowIndex: String(rowIndex + 1) });
          break;
        case "Enter":
          window.open("http://" + website, "_blank");
          navigator.clipboard.writeText(website);
          break;
        case "t":
          dispatch(
            postLabels({
              websites: [website],
              action: "trash",
              theme: query.theme!,
              table_id: query.tableId!,
            })
          );
          break;
        case "r":
          dispatch(
            postLabels({
              websites: [website],
              action: "re",
              theme: query.theme!,
              table_id: query.tableId!,
            })
          );
          break;
        case "d":
          dispatch(
            deleteLabels({
              websites: [website],
              theme: query.theme!,
            })
          );
          break;
        case "s":
          dispatch(
            toSalesforce({
              website: website,
              theme: query.theme!,
              table_id: query.tableId!,
            })
          );
          break;
        default:
          break;
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [query.tableId, rowIndex, website, query.theme]);

  return (
    <Paper
      sx={{
        padding: 1,
        display: "flex",
        alignItems: "center",
        marginBottom: 2,
        flexDirection: "row",
      }}
    >
      <ThemeSelect />
      <SpacingBox />

      <SourceTableSelect
        tableId={query.tableId!}
        onChange={(tableId) => {
          setQuery({
            tableId,
            rowIndex: "0",
          });
        }}
      />
      <SpacingBox />
      <DebouncedTextField
        label="Row index"
        defaultValue={rowIndex}
        value={rowIndex}
        size="small"
        sx={{ minWidth: 100 }}
        type="number"
        onApply={(val) => {
          setQuery({
            rowIndex: val,
          });
        }}
      />
      <SpacingBox />

      <GoodBadButton website={website} onClick={press} />
      <SpacingBox />

      <OtherActions website={website} onClick={press} />
    </Paper>
  );
}

function press(key: string) {
  const e = new KeyboardEvent("keydown", { key });
  document.dispatchEvent(e);
  snack.info(`Use shortcuts: ⬆️, ⬇️, ⬅️, ➡️, Enter, t, r, d`);
}
