import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import useLocalStorage from "../../hooks/useLocalStorage";
import { GridColumnVisibilityModel } from "@mui/x-data-grid-premium";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import SpacingBox from "../SpacingBox";
import Scroller from "../scroller/Scroller";
import CompanySwiperControls, { pageSize } from "./CompanySwiperControls";
import useQuery from "../../hooks/useQuery";
import {
  ICompanySwiperQuery,
  companySwiperQuerySchema,
} from "./CompanySwiperQuery";
import { postLabels } from "./labelsSlice";
import CompanySwiperDetails from "./CompanySwiperDetails";

const preferredOrder = [
  "",
  "name",
  "website",
  "people_count",
  "states_count",
  "locations_count",
  "all_pages_locations_count",
  "states",
  "all_pages_states_count",
  "pages_seen",
  "pages_count",
  "employees_count_max",
  "_nationwide",
  "_nation",
  "_national",
  "_nationally",
  "semantic_keywords",
  "distinct_good_words",
  "good_words_total_count",

  "_label",
  "_sem_score",

  "bad_words_total_count",
  "cluster",
  "distinct_flags",
  "flags",
  "flags_count",
  "growth_signal",
  "score_per_flag",
  "semrush_rank",
  "total_score",
  "total_sem_score",
  "sf_priority",
  "sf_status",
  "distintc_bad_words",
].reduce((acc, item, index) => {
  acc[item] = index;
  return acc;
}, {} as { [key: string]: number });
function sortColumns(items: string[], email?: string) {
  if (email !== "rfinn@princetonequity.com") {
    return items;
  }
  const itemsDict: { [key: string]: number } = {};
  items.forEach((item, index) => {
    itemsDict[item] = index;
  });
  return items.sort((a, b) => {
    const aSpelled = a.endsWith("_label")
      ? "_label"
      : a.endsWith("_sem_score")
      ? "_sem_score"
      : a;
    const bSpelled = b.endsWith("_label")
      ? "_label"
      : b.endsWith("_sem_score")
      ? "_sem_score"
      : b;
    const aIndex = preferredOrder[aSpelled] || 100 + itemsDict[a];
    const bIndex = preferredOrder[bSpelled] || 100 + itemsDict[b];
    return aIndex - bIndex;
  });
}

export default function CompanySwiperView() {
  const { query } = useQuery<ICompanySwiperQuery>(companySwiperQuerySchema);
  const rowIndex = query.rowIndex || 0;
  const email = useSelector((state: RootState) => state.session.email);
  const [columnVisibilityModel] = useLocalStorage<GridColumnVisibilityModel>(
    "columnsVisibilityModel",
    {} as GridColumnVisibilityModel
  );
  const dispatch = useDispatch();
  const company = useSelector((state: RootState) => {
    const c: { [key: string]: any } = {};
    const columns = sortColumns(
      state.table.columns.map((c) => c.field),
      email
    );
    columns.forEach((columnName) => {
      if (columnVisibilityModel[columnName] !== false) {
        const value =
          state.table.rows[Number(rowIndex) % pageSize]?.[columnName];
        c[columnName] = value;
        //
        if (
          typeof value === "string" &&
          (value.includes(";") || value.includes(","))
        ) {
          // add space after ; or , in one step
          c[columnName] = value.replace(/([;,])/g, "$1 ");
        }
      }
    });
    return c;
  });

  const isLoading = useSelector(
    (state: RootState) => state.table.status === "loading"
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CompanySwiperControls />
      {query.tableId && !isLoading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {/* screenshot */}

          <Paper
            sx={{
              display: "flex",
              flex: 1,
              flexGrow: 1,
            }}
          >
            <Scroller>
              <Box
                sx={{
                  flex: 1,
                  // hidden scrollbar
                  overflowY: "auto",
                  "&::-webkitScrollbar": {
                    display: "none",
                  },
                  MsOverflowStyle: "none",
                  scrollbarWidth: "none",
                  position: "relative",
                }}
              >
                <img
                  onError={(e) => {
                    const alt = `https://storage.googleapis.com/peg-sandbox-screenshots/www.${company.website}.webp`;
                    // @ts-ignore
                    if (e.target.src !== alt) {
                      // @ts-ignore
                      e.target.src = alt;
                    } else {
                      dispatch(
                        postLabels(
                          {
                            websites: [company.website],
                            action: "re",
                          },
                          true
                        )
                      );
                    }
                  }}
                  src={`https://storage.googleapis.com/peg-sandbox-screenshots/${company.website}.webp`}
                  style={{
                    width: "100%",
                    // height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
            </Scroller>
          </Paper>

          <SpacingBox />
          {/* company info */}
          <CompanySwiperDetails company={company} />
        </Box>
      )}
    </Box>
  );
}
