import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import SpacingBox from "../SpacingBox";
import Badge from "@mui/material/Badge";

interface IProps {
  website: string;
  onClick: (key: string) => void;
}
export default function GoodBadButton(props: IProps) {
  const { goodCompanies, badCompanies } = useSelector(
    (state: RootState) => state.labels
  );
  return (
    <>
      <Badge
        max={999}
        badgeContent={Object.keys(goodCompanies).length}
        color="secondary"
      >
        <Button
          variant={goodCompanies[props.website] && "contained"}
          sx={{ width: 90 }}
          onClick={() => props.onClick("ArrowUp")}
        >
          ⬆️ good
        </Button>
      </Badge>
      <SpacingBox />
      <Badge
        max={999}
        badgeContent={Object.keys(badCompanies).length}
        color="secondary"
      >
        <Button
          variant={badCompanies[props.website] && "contained"}
          sx={{ width: 90 }}
          onClick={() => props.onClick("ArrowDown")}
        >
          ⬇️ bad
        </Button>
      </Badge>
    </>
  );
}
