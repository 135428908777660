import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { GridRenderCellParams } from "@mui/x-data-grid";
import Link from "@mui/material/Link";
import { postLabels } from "../companySwiper/labelsSlice";
import { useDispatch } from "react-redux";
import useQuery from "../../hooks/useQuery";
import { ICompanySwiperQuery } from "../companySwiper/CompanySwiperQuery";

interface GridCellExpandProps {
  value: string;
}

const GridCellExpand = React.memo(function GridCellExpand(
  props: GridCellExpandProps
) {
  const { value } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopperClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      onMouseEnter={handlePopperOpen}
      onMouseLeave={handlePopperClose}
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      <Link
        sx={{
          textDecoration: "none",
        }}
        href={`http://${value}`}
        onClick={() => {
          navigator.clipboard.writeText(value);
        }}
        target="_blank"
      >
        {value}
      </Link>

      <Popper
        sx={{
          zIndex: 3,
          width: 895,
          height: 503,
        }}
        open={open}
        anchorEl={anchorEl}
        placement="right-end"
      >
        <PopperContent website={value} />
      </Popper>
    </Box>
  );
});

export default function WebsiteCell(params: GridRenderCellParams<any, string>) {
  return <GridCellExpand value={params.value || ""} />;
}

interface IPopperContentProps {
  website: string;
}

function PopperContent(props: IPopperContentProps) {
  const { website } = props;
  const { query } = useQuery<ICompanySwiperQuery>();
  const dispatch = useDispatch();

  return (
    <Paper elevation={8}>
      <img
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
        }}
        onError={(e) => {
          const alt = `https://storage.googleapis.com/peg-sandbox-screenshots/www.${website}.webp`;
          // @ts-ignore
          if (e.target.src !== alt) {
            // @ts-ignore
            e.target.src = alt;
          } else {
            dispatch(
              postLabels(
                {
                  websites: [website],
                  action: "re",
                },
                true
              )
            );
          }
        }}
        src={`https://storage.googleapis.com/peg-sandbox-screenshots/${website}.webp`}
      />
    </Paper>
  );
}
